<template>
    <a-modal
        :visible="visible"
        :closable="false"
        :centered="true"
        :title="null"
        :footer="null"
        width="320px"
    >
      <div style="font-size: 16px; font-weight: bold;">
        <a-icon type="exclamation-circle" theme="filled" style="color: #FAAD14;font-size: 18px;"/>
        是否{{ type == 1 ? '禁用' : '正常' }}该用户？
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;" v-if="type == '1'"> 
        禁用状态下，用户不能登录自己的账号
        账号的内容可以不能被搜索到
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;" v-else>
        正常状态下，用户可以登录自己的账号 
        账号的内容可以被搜索到
      </div>
      <div>
        <a-button style="width: 180px;" type="primary" @click="confirmStatus">确认</a-button>
        <a-button style="margin-left: 15px;" @click="$emit('cancelStatus');">取消</a-button>
      </div>
    </a-modal>
</template>

<script>

export default {
  name: "StatusModal",
  props: {
    type: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  mounted(){
    console.log(this.type)
  },
  methods: {
    confirmStatus(val) {
      console.log('val', val)
      this.$emit('confirmStatus');
    },
  },
};
</script>
